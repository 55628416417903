import './App.css'

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'

import * as React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { Route, Routes } from 'react-router-dom'
import { useState } from 'react';
import { Box, Button, CircularProgress } from '@mui/material'
import { lightTheme } from './themes/light.theme';
import { ConstructUserCookie } from '@/helper/utilities';

// import Grid from '@mui/material/Grid'
/*
import Header from './components/Header';
import Sidebar from './components/Sidebar'
import Settings from './pages/settings/settings`;
import Login from './pages/login/login'
import ConnectAcc from './pages/login/connectAcc'
import FocusContacts from './pages/contacts/focusContacts'
import Tones from './pages/tones/tones'
import Topics from './pages/topics/topics'
import PostQueue from './pages/postQueue/postQueue'

import Config from './clientConfig/config'

import '@fontsource/work-sans'
import CSVResults from './pages/contacts/csvResults';
*/

import Settings from './pages/settings/settings'
import AdminSettings from './pages/admin/adminSettings'

import Setup from '../src/views/setup'
import Main from '../src/views/main'
import Support from '../src/views/support'
import AuthSuccess from '../src/views/authSuccess'
import { SetCookie, GetCookie } from './helper/cookieManager'

import Login from '../src/pages/login/login'
import Unauthorized from '../src/views/unauthorized'
import Unsubscribe from '../src/views/unsubscribe'

import '@fontsource/source-sans-pro'
import '@fontsource/poppins'
import "@fontsource/inter"
import "@fontsource/inter/500.css"
import "@fontsource/inter/600.css"
import "@fontsource/inter/700.css"
import "@fontsource/inter/800.css"
import "@fontsource/inter/900.css"
import "@fontsource/black-han-sans";

import "@fontsource/roboto";
import "@fontsource/roboto/500.css";
import { configureStore } from '@reduxjs/toolkit'

import { useDispatch, Provider, useSelector } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from '../src/redux/store'
import '@fontsource/oxygen'
import { SetupConst, ProviderType, Milestones } from './constants/constant'
import { SnackbarProvider } from './contexts/SnackbarContext'
import AutomationLogin from './pages/login/automationLogin'
import {
  getSequences,
  getSequenceContacts,
  getUserPostQueue,
  getSECForm144ByContactIds,
  getFundzRecordsByContactIds, getAllUserSequenceContacts,
  getUserDelegationsList,
  getCompany,
} from '@/helper/apiHelper';

import {
  setUser as setAuthSliceUser,
  setContacts as setAuthSliceContacts,
  setSequences as setAuthSliceSequences,
  setPosts as setAuthSlicePosts,
  setDelegations as setAuthSliceDelegations,
  setCompany as setAuthSliceCompany,
  hydrateSelectedDelegation as hydrateAuthSliceSelectedDelegation,
  setActionFlag as setAuthSliceActionFlag,
} from '@/redux/authSlice';
import _ from 'lodash';

TimeAgo.addDefaultLocale(en)

const MAX_COOKIE_SIZE = 4000

export const refreshSequencesAndContacts = async (dispatch) => {
  const sequences = await getSequences();
  const allContacts = [];

  if (sequences) {
    // THIS IS A NEW WAY TO FETCH ALL CONTACTS FOR ALL SEQUENCES IN ONE GO VS FETCHING CONTACTS FOR EACH SEQUENCE ONE BY ONE
    // THIS IS NOT YET IMPLEMENTED IN THE FRONTEND TODO: SOMEONE NEEDS TO IMPLEMENT THIS
    // const sequenceIds = sequences.map(seq => seq._id);
    // const userSequenceContacts = await getAllUserSequenceContacts(sequenceIds);
    for (const seq of sequences) {
      const response = await getSequenceContacts(seq._id);
      let contacts = [];
      if (response && response.contacts) {
        contacts = response.contacts;
      }
      if (contacts) {
        allContacts.push(...contacts);

        const contactHash = allContacts.reduce((acc, contact) => {
          acc[contact._id] = contact;
          return acc;
        }, {});
        seq.contacts = contactHash;
      }
    }

    dispatch(setAuthSliceContacts(allContacts));

    const seqHash = sequences.reduce((acc, seq) => {
      acc[seq._id] = seq;
      return acc;
    }, {});

    dispatch(setAuthSliceSequences(seqHash));
  }
};

function shouldHidePost(post) {
  return (
    post?.sequenceAttr &&
    post?.sequenceAttr.milestone === Milestones.FUNDING &&
    (_.isNil(post.sequenceAttr?.milestoneDetails?.source) || _.isEmpty(post.sequenceAttr?.milestoneDetails?.source))
  );
}

export const refreshPosts = async (dispatch, accessId) => {
  const userPostQueue = await getUserPostQueue(accessId);
  if (userPostQueue) {
    // Filter out posts that meet the condition to be hidden
    const filteredPosts = userPostQueue.filter((post) => !shouldHidePost(post));
    dispatch(setAuthSlicePosts(filteredPosts));
  } else {
    dispatch(setAuthSlicePosts([]));
  }
};

export const refreshDelegations = async (dispatch) => {
  const delegations = await getUserDelegationsList();
  if (delegations) {
    dispatch(setAuthSliceDelegations(delegations));
  }
};

export const refreshCompany = async (dispatch) => {
  const company = await getCompany();
  dispatch(setAuthSliceCompany(company));
};

function WrappedApp() {
  const dispatch = useDispatch();
  const authSliceSelectedDelegation = useSelector(state => state.auth.selectedDelegation);

  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isInitialSetupDone, setIsInitialSetupDone] = useState(false)
  const [userRoles, setUserRoles] = useState([])
  const [isSubscribed, setIsSubscribed] = useState(false)

  const subscribeBtnRef = React.useRef(null)

  const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4)
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/')
    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }

  const subscribeToNotifications = async () => {
    try {
      const sw = await navigator.serviceWorker.ready
      console.log('Service Worker State:', sw.state)

      const publicKey = urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY || 'BIw27cNW3OkOr6iqbweJiJ-N2vU4NT2YwtrZlssS-9wRPtv6L0bwGVPlQbn-OuXdwcmDpGP0Ga5YqmlRBGcP-as')
      const subscription = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: publicKey
      })

      const response = await fetch('/api/subscribe', {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true
        },
        body: JSON.stringify(subscription)
      })

      if (!response.ok) {
        console.error('Server responded with status:', response.status)
        return
      }

      setIsSubscribed(true)
    } catch (error) {
      console.error('Failed to subscribe the user: ', error)
    }
  }

  React.useEffect(() => {
    const getUser = () => {
      let status = null

      fetch('/api/auth/login/success', {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true
        }
      })
        .then((response) => {
          status = response.status
          if (status === 200) {
            setIsAuthenticated(true)
            return response.json()
          }
          throw new Error('authentication has been failed!')
        })
        .then((resObject) => {
          setUser(resObject.user)
          dispatch(setAuthSliceUser(resObject.user))
          const userData = ConstructUserCookie(resObject.user, GetCookie('user'))
          SetCookie('user', userData)
          setIsAuthenticated(true)
          setIsInitialSetupDone(resObject?.user?.initialSetupDone)
          setUserRoles(resObject.user.userRoles)
        })
        .catch((err) => {
          setIsAuthenticated(false)
          setUserRoles([])
          setUser(null)
          dispatch(setAuthSliceUser(null))
          setLoading(false)
          console.error(err)
        }).finally(() => {
          setLoading(false)
          const authChannel = new BroadcastChannel('authchannel')
          authChannel.postMessage({
            message: 'auth-success',
            url: window.location.href,
            status: status
          })
        })
    }
    getUser()



    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.ready.then((sw) => {
        sw.pushManager.getSubscription().then((subscription) => {
          setIsSubscribed(!!subscription)
        })
      })
    }
  }, [])

  // Handle post refresh triggered explicitly
  const refreshPostsFlag = useSelector((state) => state.auth.actionFlags.refreshPosts);
  React.useEffect(() => {
    if (refreshPostsFlag) {
      refreshPosts(dispatch, authSliceSelectedDelegation?.userId);
      dispatch(setAuthSliceActionFlag({ action: 'refreshPosts', flag: false }));
    }
  }, [refreshPostsFlag, dispatch, authSliceSelectedDelegation?.userId]);

  // Handle post refresh triggered implicitly
  React.useEffect(() => {
    if (user?._id) {
      refreshPosts(dispatch, authSliceSelectedDelegation?.userId);
    }
  }, [user?._id, dispatch, authSliceSelectedDelegation?.userId]);

  React.useEffect(() => {
    if (user) {
      dispatch(hydrateAuthSliceSelectedDelegation());

      try {
        refreshSequencesAndContacts(dispatch);
      } catch (error) {
        console.warn(error);
      }
      try {
        refreshDelegations(dispatch);
      } catch (error) {
        console.warn(error);
      }
      try {
        refreshCompany(dispatch);
      } catch (error) {
        console.warn(error);
      }
      try {
        if (window?.location?.hostname === "postilize-stage.herokuapp.com") {
          window.checksumai?.identify(
            user.id, // distinct_id, required
            {
              userRoles: user.userRoles,
              userType: user.userType,
              email: user.emailNotifier?.email,
              name: user.name,
            }
          );
        }
      } catch (error) {
        console.log("Failed calling checksum identify");
      }
    }
  }, [dispatch, user]);

  React.useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const registration = await navigator.serviceWorker.ready
        const subscription = await registration.pushManager.getSubscription()
        if (subscription) {
          updateButton('unsubscribe')
        } else {
          updateButton('subscribe')
        }
      } catch (error) {
        console.error('Error checking subscription status:', error)
      }
    }

    checkSubscriptionStatus()
  }, [])

  const handleSubscriptionClick = async () => {
    if (isSubscribed) {
      await unsubscribeFromNotifications()
    } else {
      await subscribeToNotifications()
    }
  }

  const updateButton = (action) => {
    if (action === 'subscribe') {
      setIsSubscribed(false)
    } else {
      setIsSubscribed(true)
    }
  }

  function unsubscribeFromNotifications() {
    navigator.serviceWorker.ready
      .then((registration) => {
        return registration.pushManager.getSubscription()
      })
      .then((subscription) => {
        if (!subscription) {
          console.error('User is not subscribed.')
          setIsSubscribed(false)
          return
        }

        return fetch('/api/unsubscribe', {
          method: 'POST',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true
          },
          body: JSON.stringify(subscription)
        }).then((response) => {
          if (!response.ok) {
            throw new Error('Failed to unsubscribe on the server.')
          }
          return subscription.unsubscribe()
        })
      })
      .then(() => {
        console.log('User is unsubscribed.')
        setIsSubscribed(false)
        updateButton('subscribe')
      })
      .catch((error) => {
        console.error('Error unsubscribing', error)
      })
  }

  if (loading) {
    return (
      <Box
        sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '200px',
          height: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress color='inherit' />
      </Box>
    )
  }

  return (
    <>
      {isAuthenticated && (
        <Box
          sx={{
            position: 'absolute',
            top: 8,
            right: 16,
            zIndex: 9999,
          }}
        ></Box>
      )}
      <Routes>
        {/* <Route path='/' element={isAuthenticated ? (isInitialSetupDone ? <Main /> : <Setup />) : <Login />} /> */}
        <Route path="/" element={isAuthenticated ? <Main /> : <Login />} />
        <Route path="/setup" element={isAuthenticated ? <Setup /> : <Login />} />
        <Route path="/authSuccess" element={isAuthenticated ? <AuthSuccess /> : <Login />} />
        <Route path="/main" element={isAuthenticated ? <Main /> : <Login />} />
        <Route path="/settings" element={isAuthenticated ? <Settings /> : <Login />} />
        <Route
          path="/admin"
          element={isAuthenticated ? userRoles?.includes('admin') ? <AdminSettings /> : <Unauthorized /> : <Login />}
        />
        <Route path="/support" element={<Support />} />
        <Route path="/automationLogin" element={<AutomationLogin />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
      </Routes>
    </>
  );
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={lightTheme}>
          <SnackbarProvider>
            <WrappedApp />
          </SnackbarProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App