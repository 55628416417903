// Implements "setup v2" for the following notion task:
// https://www.notion.so/TASK-2065
import React, { useMemo } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import PTypography from './PTypography';
import PProgressBar from './PProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import { setPage, setSettingsPageOverride } from '@/redux/authSlice';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ExtensionIcon from '@mui/icons-material/Extension';
import AppleIcon from '@mui/icons-material/Apple';
import ListIcon from '@mui/icons-material/List';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PToolTip from '@Library/PToolTip';

const StyledPOnboardingCompletionPanel = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(5),
  borderRadius: '14px',
  border: `1px solid ${theme.palette.primaryCL.Black70}`,
  padding: '8px',
}));

function calculateProgress(items) {
  const completedItems = items.filter((item) => item.completed).length;
  return Math.round((completedItems / items.length) * 100);
}

const openInNewIcon = <OpenInNewIcon sx={{ width: 15, height: 15, transform: 'translate(-3px, 5px)' }} />;

function POnboardingCompletionPanel() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isLinkedInConnected = user.providers?.some((provider) => provider.provider === 'linkedin' && provider.active);

  const progressItems = useMemo(
    () => [
      { text: 'Account created', completed: true, icon: <AccountBoxIcon /> },
      {
        text: 'LinkedIn connected',
        completed: isLinkedInConnected,
        icon: <LinkedInIcon />,
        onClick: () => {
          dispatch(setSettingsPageOverride('Social'));
          dispatch(setPage({ payload: 'settings' }));
        },
      },
      {
        text: 'Chrome extension',
        tooltip: user.setupSteps?.connectLinkedInExtensionRemarks,
        completed: user.setupSteps?.connectLinkedInExtension,
        icon: <ExtensionIcon />,
        onClick: () => {
          window.open(
            'https://chromewebstore.google.com/detail/postilize-digital-assista/eheabolgahkaiemegedcpfeklmdmmbgb',
            '_blank',
          );
        },
        extraIcon: openInNewIcon,
      },
      {
        text: 'iOS app',
        completed: user.setupSteps?.connectMobileApp,
        icon: <AppleIcon />,
        onClick: () => {
          window.open('https://apps.apple.com/ph/app/postilize/id6503707086', '_blank');
        },
        extraIcon: openInNewIcon,
      },
      {
        text: 'Social topics',
        completed: user.topics?.length > 0,
        icon: <ListIcon />,
        onClick: () => {
          dispatch(setSettingsPageOverride('Topics'));
          dispatch(setPage({ payload: 'settings' }));
        },
      },
    ],
    [
      isLinkedInConnected,
      user.setupSteps?.connectLinkedInExtension,
      user.setupSteps?.connectMobileApp,
      user.topics?.length,
      dispatch,
    ],
  );

  const memoizedContent = useMemo(() => {
    const progress = calculateProgress(progressItems);
    return (
      progress !== 100 && (
        <StyledPOnboardingCompletionPanel>
          <Box display="flex" justifyContent="space-between" alignItems="center" paddingBottom={1}>
            <PTypography size="h1">Setup Progress</PTypography>
            <PTypography size="h1">{progress}%</PTypography>
          </Box>
          <Box width="100%" marginBottom={2} sx={{ border: '0.5px solid black' }}>
            <PProgressBar progress={progress} />
          </Box>
          <Stack spacing={1}>
            {progressItems.map((item, index) => (
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  cursor: item.completed ? 'default' : item.onClick ? 'pointer' : 'inherit',
                }}
                onClick={() => !item.completed && item.onClick && item.onClick(item)}
              >
                <Box width="24px" height="24px" marginRight={1}>
                  {item.icon && item.icon}
                </Box>
                <PTypography
                  key={index}
                  color={item.completed ? theme.palette.primaryCL.Green100 : 'inherit'}
                  size="body1"
                  weight={item.completed ? 'regular' : 'bold'}
                  sx={{
                    textDecoration: item.completed ? 'line-through' : 'none',
                  }}
                >
                  {item.tooltip ? (
                    <PToolTip title={item.tooltip} placement="right">
                      <>{item.text}</>
                    </PToolTip>
                  ) : (
                    item.text
                  )}
                </PTypography>
                {item.extraIcon && (
                  <Box width="24px" height="24px" marginLeft={1}>
                    {item.extraIcon}
                  </Box>
                )}
              </Box>
            ))}
          </Stack>
        </StyledPOnboardingCompletionPanel>
      )
    );
  }, [theme, progressItems]);

  return memoizedContent;
}

POnboardingCompletionPanel.propTypes = {};

export default POnboardingCompletionPanel;
