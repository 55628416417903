import { useState, useEffect } from 'react';
import {
  Box,
  MenuItem,
  Select,
  CircularProgress,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';

import Http from '../../../http/httpClient';
import ReactJson from '@microlink/react-json-view';

const JsonTableCell = ({ data, collapsed = false, displayObjectSize = false, collapseStringsAfterLength = false }) => (
  <TableCell>
    <ReactJson
      src={data}
      theme="pop"
      enableClipboard={false}
      iconStyle="triangle"
      collapseStringsAfterLength={collapseStringsAfterLength}
      displayObjectSize={displayObjectSize}
      displayDataTypes={false}
      name={false}
      collapsed={collapsed}
    />
  </TableCell>
);

function jsonToCSV(data) {
  if (data.length === 0) return '';

  const headers = Object.keys(data[0]);
  const rows = data.map((item) => {
    return headers
      .map((header) => {
        const value = item[header];
        return value !== null && value !== undefined ? `"${value}"` : '';
      })
      .join(',');
  });
  return [headers.join(','), ...rows].join('\n');
}

export default function ManageUserSequences() {
  const [users, setUsers] = useState([]);

  // Flags for spinners
  const [usersLoading, setUsersLoading] = useState(true);
  const [sequencesLoading, setSequencesLoading] = useState(false);
  const [candidatesLoading, setCandidatesLoading] = useState(false);

  const [selectedUser, setSelectedUser] = useState('');
  const [userSequences, setUserSequences] = useState([]);
  const [selectedSequenceId, setSelectedSequenceId] = useState('');
  const [selectedSequence, setSelectedSequence] = useState(null);
  const [contactCandidates, setContactCandidates] = useState([]);
  const [showContactCandidates, setShowContactCandidates] = useState(false);

  const fetchAvailableUsers = async () => {
    Http.getData('users/list')
      .then((res) => {
        const resUsers = res.users.map((item) => {
          return { id: item._id.toString(), name: item.name, email: item.email };
        });
        setUsers(resUsers);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setUsersLoading(false);
      });
  };

  const fetchUserSequences = async () => {
    Http.getData('userseq/list', { userId: selectedUser })
      .then((res) => {
        setUserSequences(res.result);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSequencesLoading(false);
      });
  };

  const proposeContacts = async () => {
    Http.getData('userseq/listContactCandidates', { userId: selectedUser, sequenceId: selectedSequenceId })
      .then((res) => {
        setContactCandidates(res.result);
        setShowContactCandidates(true);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setCandidatesLoading(false);
      });
  };

  useEffect(() => {
    fetchAvailableUsers();
  }, []);

  useEffect(() => {
    if (selectedUser?.length > 0) {
      setSequencesLoading(true);
      fetchUserSequences();
    }
  }, [selectedUser]);

  useEffect(() => {
    if (selectedSequenceId) {
      const currentSeq = userSequences?.find((x) => x._id === selectedSequenceId);
      if (currentSeq) setSelectedSequence(currentSeq);
    }
  }, [selectedSequenceId, userSequences]);

  const handleUserChange = (e) => {
    setSelectedUser(e.target.value);
    setSelectedSequenceId(null);
    setSelectedSequence(null);
    setContactCandidates([]);
  };

  const handleSequenceChange = (e) => {
    setSelectedSequenceId(e.target.value);
    setContactCandidates([]);
    setShowContactCandidates(false);
  };

  const refreshUsers = async () => {
    setUsersLoading(true);
    fetchAvailableUsers();
  };

  const refreshUserSequences = async () => {
    setSequencesLoading(true);
    fetchUserSequences();
  };

  const handleProposeContacts = async () => {
    setCandidatesLoading(true);
    proposeContacts();
  };

  const exportProposedContacts = (contacts) => {
    if (!contacts || contacts.length === 0) {
      console.log('No contacts to export');
      return;
    }

    const csvData = jsonToCSV(contacts);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'proposed_contacts.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <h3>Manage User Sequences</h3>
      <Box display="flex" flexDirection="column" gap={2} alignItems="flex-start" sx={{ width: '100%' }}>
        <Box sx={{ flexGrow: 1, mx: 2, width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {usersLoading ? (
              <Box
                sx={{
                  position: 'relative',
                  width: '52px',
                  height: '34px',
                  display: 'flex',
                  borderRadius: 16,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            ) : (
              <>
                {/* User dropdown */}
                <Select value={selectedUser} onChange={handleUserChange} sx={{ minWidth: 200 }}>
                  {users.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name} - {user.email}
                    </MenuItem>
                  ))}
                </Select>
                <Button
                  variant="outlined"
                  sx={{
                    'marginLeft': 2,
                    'color': 'white',
                    'backgroundColor': '#3983F7',
                    'textTransform': 'none',
                    'display': 'flex',
                    'alignItems': 'center',
                    'fontSize': '15px',
                    'borderRadius': 10,
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white',
                    },
                  }}
                  onClick={refreshUsers}
                >
                  Refresh
                </Button>
              </>
            )}
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {sequencesLoading ? (
              <Box
                sx={{
                  position: 'relative',
                  width: '52px',
                  height: '34px',
                  display: 'flex',
                  borderRadius: 16,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            ) : (
              <>
                {/* Sequence dropdown */}
                <Select value={selectedSequenceId} onChange={handleSequenceChange} sx={{ minWidth: 200 }}>
                  {userSequences?.map((seq) => (
                    <MenuItem key={seq._id} value={seq._id}>
                      {seq.name} - {seq.status}
                    </MenuItem>
                  ))}
                </Select>
                <Button
                  variant="outlined"
                  sx={{
                    'marginLeft': 2,
                    'color': 'white',
                    'backgroundColor': '#3983F7',
                    'textTransform': 'none',
                    'display': 'flex',
                    'alignItems': 'center',
                    'fontSize': '15px',
                    'borderRadius': 10,
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white',
                    },
                  }}
                  onClick={refreshUserSequences}
                >
                  Refresh
                </Button>
              </>
            )}
          </Box>

          {sequencesLoading ? (
            <></>
          ) : (
            // <Box
            //   sx={{
            //     position: 'relative',
            //     width: '52px',
            //     height: '34px',
            //     display: 'flex',
            //     borderRadius: 16,
            //     alignItems: 'center',
            //     justifyContent: 'center',
            //   }}
            // >
            //   <CircularProgress color="inherit" />
            // </Box>
            selectedSequence && (
              <Box sx={{ my: 2, mr: 3, p: 2 }}>
                <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
                  <Table sx={{ minWidth: 650 }} stickyHeader aria-label="preference table">
                    <TableBody>
                      <TableRow key={'User Sequence'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left">
                          <JsonTableCell data={selectedSequence} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button
                  variant="outlined"
                  sx={{
                    'marginLeft': 2,
                    'color': 'white',
                    'backgroundColor': '#3983F7',
                    'textTransform': 'none',
                    'display': 'flex',
                    'alignItems': 'center',
                    'fontSize': '15px',
                    'borderRadius': 10,
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white',
                    },
                  }}
                  onClick={handleProposeContacts}
                >
                  Propose Contacts
                </Button>
              </Box>
            )
          )}

          {candidatesLoading ? (
            <Box
              sx={{
                position: 'relative',
                width: '52px',
                height: '34px',
                display: 'flex',
                borderRadius: 16,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            showContactCandidates > 0 && (
              <Box sx={{ my: 2, mr: 3, p: 2 }}>
                <TableContainer component={Paper} sx={{ maxHeight: 650 }}>
                  <Table sx={{ minWidth: 650 }} stickyHeader aria-label="preference table">
                    <TableBody>
                      <TableRow key={'Proposed Contacts'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left">
                          <JsonTableCell data={contactCandidates} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button
                  variant="outlined"
                  sx={{
                    'marginLeft': 2,
                    'color': 'white',
                    'backgroundColor': '#3983F7',
                    'textTransform': 'none',
                    'display': 'flex',
                    'alignItems': 'center',
                    'fontSize': '15px',
                    'borderRadius': 10,
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white',
                    },
                  }}
                  onClick={() => exportProposedContacts(contactCandidates)}
                >
                  Export Proposed Contacts
                </Button>
              </Box>
            )
          )}
        </Box>
      </Box>
    </>
  );
}
