import PBadge from '../components/library/PBadge';

export const SetupConst = Object.freeze({
  Steps: {
    TONES: 1,
    TOPICS: 2,
    IMPORT_LIST: 3,
    IMPORT_LIST_FILTERS: 4,
    FOCUS_CONTACTS: 5,
    CONNECT: 6,
  },
});

export const SocialNetworks = Object.freeze(['twitter', 'linkedin']);

export const Providers = Object.freeze({
  TWITTER: 'twitter',
  LINKED_IN: 'linkedin',
  SALESFORCE: 'salesforce',
  HUBSPOT: 'hubspot',
  CSV: 'csv',
  GOOGLE: 'google',
  AFFINITY: 'affinity',
  LINKED_IN_BASIC: 'linkedin_basic',
});

export const PostPriority = Object.freeze({
  SCHEDULED: 'scheduled',
  IMMEDIATE: 'immediate',
});

export const CharacterLimits = Object.freeze({
  TWITTER: 280,
  LINKEDIN: 2000,
});

export const ContactsFilter = Object.freeze({
  TWITTER_MAXIMUM_CONTACTS: 500,
});

export const Regexes = Object.freeze({
  EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  EMAIL_DOMAINS:
    /^([a-zA-Z0-9]+(([-.][a-zA-Z0-9]+)*)([.][a-zA-Z0-9]+))(,[a-zA-Z0-9]+(([-.][a-zA-Z0-9]+)*)([.][a-zA-Z0-9]+))*$/,
  WEBSITE_URL: /^(https?:[//]{2})?([a-zA-Z0-9]+([-.]?[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/,
});

export const TopicType = Object.freeze({
  USER_POST: 'userPost',
  TWITTER_TRENDING: 'twitterTrending',
  IMAGE_POST: 'imagePost',
  LINKEDIN_EMAIL: 'linkedInEmail',
});

export const ProviderType = Object.freeze({
  SOCIAL_NETWORKS: ['twitter', 'linkedin'],
  IMPORT_LIST: ['salesforce', 'hubspot', 'csv', 'affinity'],
  IMPORT_LIST_FILTERS: ['salesforce', 'hubspot', 'google', 'affinity'],
});

export const PostSources = Object.freeze({
  TWITTER: 'twitter',
  LINKEDIN_COMMENT: 'linkedin-comment',
  SEQUENCE: 'sequence',
});

export const SequenceStepType = Object.freeze({
  EMAIL: 'email',
  TWITTER_AUTO_LIKE: 'twitter_auto_like',
  TWITTER_AUTO_RETWEET: 'twitter_auto_retweet',
  TWITTER_AUTO_FOLLOW: 'twitter_auto_follow',
});

export const TwitterActionStepTypes = Object.freeze([
  'twitter_auto_like',
  'twitter_auto_retweet',
  'twitter_auto_follow',
]);

export const SequenceDefaults = Object.freeze({
  START_MIN_INTERVAL: 1,
  START_INTERVAL: 2,
  START_FREQUENCY: 'hour',
  STEP_START_INTERVAL: 1,
  STEP_START_FREQUENCY: 'day',
});

export const SequenceStepStatus = Object.freeze({
  CREATED: 'created',
  QUEUED: 'queued',
  RETRY: 'retry',
  TRIGGERED: 'triggered',
  FAILED: 'failed',
  SKIPPED: 'skipped',
  CANCELLED: 'cancelled',
  NOTAPPROVED: 'notapproved',
  LIMITEXCEEDED: 'limitexceeded',
  MANUALLY_DELETED: 'manuallyDeleted',
});

export const SequenceMaxIntervals = Object.freeze({
  HOUR: 2160,
  DAY: 90,
  MONTH: 3,
});

export const Severity = Object.freeze({
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
});

export const SequenceEmailPlaceholders = Object.freeze([
  { name: '{name}', value: 'Alice Adams' },
  { name: '{first_name}', value: 'Alice' },
  { name: '{last_name}', value: 'Adams' },
  { name: '{title}', value: 'Sales Manager' },
  { name: '{company}', value: 'ABC Technologies' },
]);

export const Frequencies = Object.freeze({
  20: 'Rarely',
  50: 'Sometimes',
  80: 'Often',
});

export const TwitterStepIds = ['connect', 'follow', 'like', 'share', 'comment'];
export const StepFrequencies = ['Rarely', 'Sometimes', 'Often'];
export const StepSaleValues = {
  '$500K +': 500000,
  '$1M +': 1000000,
  '$5M +': 5000000,
  '$10M +': 10000000,
};

export const Milestones = Object.freeze({
  SEC144: 'sec144',
  BIRTHDAY: 'Birthday',
  CONTRACT_RENEWAL: 'Contract Renewal',
  ANNIVERSARY: 'Anniversary',
  JOB_CHANGE: 'Job Change',
  JOB_PROMOTION: 'Job Promotion',
  NEW_CERTIFICATION: 'New Certification',
  FUNDING: 'Funding',
  WINTER_HOLIDAYS: 'Winter Holidays',
  ACQUISITION: 'Acquisition',
});

export const FeatureFlags = Object.freeze({
  FUNDING_FILTERING_BACKEND: 'fundz-filtering-backend',
  FUNDING_BD: 'ui.fundzBusinessDev',
  FUNDING_RG: 'ui.fundzRelationshipGrowth',
  FUNDING_GEOGRAPHICAL: 'ui.fundz.geographical.filters',
  MESSAGE_PLATFORM: "ui.sendToMessagePlatform",
});

export const StepSaleValuesInverse = Object.keys(StepSaleValues).reduce((acc, key) => {
  acc[StepSaleValues[key]] = key;
  return acc;
}, {});

// export const StepRecurringTimings = ['Each week', 'Each month', 'Each quarter', 'Once a year']
export const StepRecurringTimings = [
  'Once a year',
  '2 times a year',
  '3 times a year',
  '4 times a year',
  '5 times a year',
  '6 times a year',
];
export const SequenceMailableStepIds = ['email', 'meeting', 'followup', 'gift', 'checkin.recurring'];

export const aiSearchLoadingMessages = [
  'Searching your criteria...',
  'AI searching...',
  'Broadening the criteria...',
  'Checking data sources...',
];

export const PostFrequencyOptions = [
  { name: 'Once a week', badge: null },
  { name: 'Twice a week', badge: null },
  {
    name: 'Three times a week',
    badge: <PBadge pVariant="purple">Most choose this</PBadge>,
  },
  { name: 'Four times a week', badge: null },
  { name: 'Five times a week', badge: null },
];

export const RULE_ENGINE_CODES = [
  {
    CODE: 'RECENT_ENGAGEMENT',
    LABEL: 'Skipped, you were just in touch',
    WEIGHT: 1,
  },
  {
    CODE: 'ANTI_SPAM_COMPLIANCE',
    LABEL: 'Skipped, anti-spam compliance',
    WEIGHT: 2,
  },
  {
    CODE: 'CONTRACT_RENEWAL',
    LABEL: 'Skipped, upcoming contract renewal',
    WEIGHT: 3,
  },
];

export const ApprovalTypes = Object.freeze({
  APPROVE_ALL_MESSAGES: 'Approve all messages',
  APPROVE_ALL_POSTS: 'Approve all posts',
  APPROVE_ALL_REPLIES: 'Approve all replies',
  APPROVE_ALL_GIFTS: 'Approve all gifts',
  APPROVE_EVERYTHING: 'Approve everything',
});

export const Country = [
  { label: 'North America' },
  { label: 'South America' },
  { label: 'EMEA (Europe and Middle East)' },
  { label: 'Asia' },
  { label: 'Australia' },
];
