import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import DOMPurify from 'dompurify';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { TRANSFORMERS } from '@lexical/markdown';
import { $createTextNode, $createParagraphNode, $getRoot, $insertNodes } from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import ImagePlugin from './plugins/ImagePlugin';
import { MATCHERS } from './autolink-matchers';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import OnChangePlugin from './plugins/OnChangePlugin';
import { ImageNode, $isImageNode } from '@/components/library/PRTEditor/nodes/ImageNode';
import { InlineImageNode } from '@/components/library/PRTEditor/nodes/InlineImageNode';

import lexicalPRichTextEditorTheme from './themes/LexicalTheme';
import './styles.css';
import ImagePastingHandler from '@/components/library/PRTEditor/plugins/ImagePastingHandler';
import InlineImagePlugin from '@/components/library/PRTEditor/plugins/InlineImagePlugin';
import { isLexicalJSON, isLexicalHTML } from '@/helper/utilities';
import { EditorRefPlugin } from '@/components/library/PRTEditor/plugins/EditorRefPlugin';

const initializeFromJSON = (editor, initialValue) => {
  const parsedEditorState = editor.parseEditorState(initialValue);
  editor.setEditorState(parsedEditorState);
};

const initializeFromHTML = (editor, initialValue) => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(initialValue, 'text/html');
  editor.update(() => {
    const root = $getRoot();
    root.clear();
    const nodes = $generateNodesFromDOM(editor, dom);
    root.select();
    $insertNodes(nodes);
  });
};

const initializeFromPlainText = (editor, initialValue) => {
  const paragraphs = initialValue.split('\n\n');
  editor.update(() => {
    const root = $getRoot();
    root.clear();
    const nodes = [];
    paragraphs.forEach((paragraphText) => {
      const paragraph = $createParagraphNode();
      const textNode = $createTextNode(paragraphText);
      paragraph.append(textNode);
      nodes.push(paragraph);
    });
    $insertNodes(nodes);
  });
};

export default function PRTEditor({
  disabled = false,
  onBlur = null,
  onChange = null,
  onFocus = null,
  placeholder = null,
  readOnly = false,
  sx = null,
  value = null,
  initialValue = '',
  editorRef = null,
}) {
  const theme = useTheme();
  const [isEditorActive, setIsEditorActive] = React.useState(false);
  const [isEditorHovered, setIsEditorHovered] = React.useState(false);
  const [editorInitializationCount, setEditorInitializationCount] = React.useState(0);
  const [position, setPosition] = React.useState({ x: 0, y: 0 });
  const [linkEditorDisplayed, setLinkEditorDisplayed] = React.useState(false);
  const [linkUrl, setLinkUrl] = React.useState('');
  const [editMode, setEditMode] = React.useState(false);
  const [eventKey, setEventKey] = React.useState('');
  const [imagesLoading, setImagesLoading] = React.useState(0);

  const onImageLoad = React.useCallback(() => {
    setImagesLoading((prev) => prev - 1);
  }, []);

  const onImageError = React.useCallback(() => {
    setImagesLoading((prev) => prev - 1);
  }, []);

  const editorConfig = {
    theme: lexicalPRichTextEditorTheme,
    onError(error) {
      throw error;
    },
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      ImageNode,
      InlineImageNode,
    ],
  };

  const EditorInitializer = () => {
    const [editor] = useLexicalComposerContext();

    const handleImageNodes = React.useCallback((node) => {
      if ($isImageNode(node)) {
        node.setOnImageLoad(onImageLoad);
        node.setOnImageError(onImageError);
      } else if (node.getChildren) {
        node.getChildren().forEach(handleImageNodes);
      }
    }, []);

    React.useEffect(() => {
      // images fail to display on the first few renders, not sure why.
      // this is a hack to fix it.
      if (initialValue && editorInitializationCount < 10) {
        if (isLexicalJSON(initialValue)) {
          const imageNodes = initialValue.match(/"type": "image"/g);
          setImagesLoading(imageNodes?.length || 0);

          initializeFromJSON(editor, initialValue);
          editor.update(() => {
            const root = $getRoot();
            root.getChildren().forEach(handleImageNodes);
            root.updateDOM();
          });
        } else if (isLexicalHTML(initialValue)) {
          const imgTags = initialValue.match(/<img/g);
          setImagesLoading(imgTags?.length || 0);


          initializeFromHTML(editor, initialValue);
          editor.update(() => {
            const root = $getRoot();
            root.getChildren().forEach(handleImageNodes);
            root.updateDOM();
          });
        } else {
          // no images supported in plaintext
          initializeFromPlainText(editor, initialValue);
        }

        setEditorInitializationCount((prev) => prev + 1);
      }
    }, [editor, handleImageNodes]);
  };

  const Placeholder = () => {
    return <div className="editor-placeholder">{placeholder}</div>;
  };

  const handleParentDivClick = React.useCallback((event) => {
    const { clientX, clientY } = event;
    const { left, top } = event.currentTarget.getBoundingClientRect();
    const offsetX = clientX - left;
    const offsetY = clientY - top;
    setPosition({ x: offsetX - 10, y: offsetY + 20 });
  }, []);

  const linkPluginProps = {
    linkUrl,
    eventKey,
    setEditMode,
    setEventKey,
    setLinkEditorDisplayed,
    setLinkUrl,
  };

  const handleOnEditorFocused = React.useCallback(() => {
    setIsEditorActive(true);
    onFocus && onFocus();
  }, [onFocus]);

  const handleOnEditorBlurred = React.useCallback(() => {
    setIsEditorActive(false);
    onBlur && onBlur();
  }, [onBlur]);

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div
        className="prt-editor"
        style={{
          border: `solid 1px ${isEditorActive || isEditorHovered ? theme.palette.primaryCL.Blue100 : theme.palette.primaryCL.Black70}`,
          borderRadius: '14px',
          marginTop: '8px',
          width: '100%',
          paddingBottom: '14px',
          ...sx,
        }}
      >
        <ToolbarPlugin linkPluginProps={linkPluginProps} />
        <div
          className="editor-container"
          onMouseEnter={() => setIsEditorHovered(true)}
          onMouseLeave={() => setIsEditorHovered(false)}
        >
          {linkEditorDisplayed && (
            <div className="link-editor" style={{ top: `${position.y}px`, left: `${position.x}px` }}>
              {editMode ? (
                <input
                  autoFocus
                  className="link-input"
                  value={linkUrl}
                  onChange={(event) => {
                    setLinkUrl(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    setEventKey(event.key);
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      setEditMode(false);
                    }
                  }}
                  onFocus={(event) => event.target.select()}
                />
              ) : (
                <>
                  <div className="link-input">
                    <a
                      href={DOMPurify.sanitize(linkUrl)}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        setLinkEditorDisplayed(false);
                        setPosition({ x: 0, y: 0 });
                      }}
                    >
                      {linkUrl}
                    </a>
                    <div
                      className="link-edit"
                      role="button"
                      tabIndex={0}
                      onMouseDown={(event) => event.preventDefault()}
                      onClick={() => {
                        setEditMode(true);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          <div className="editor-inner">
            <RichTextPlugin
              contentEditable={
                <ContentEditable
                  className="editor-input"
                  onFocus={handleOnEditorFocused}
                  onBlur={handleOnEditorBlurred}
                  onClick={handleParentDivClick}
                  readOnly={readOnly}
                  disabled={disabled}
                />
              }
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <EditorRefPlugin editorRef={editorRef} />
            <ImagePastingHandler />
            <ImagePlugin onImageLoad={onImageLoad} onImageError={onImageError} />
            {/* <InlineImagePlugin /> */}
            {/* <CodeHighlightPlugin /> */}
            <OnChangePlugin onChange={onChange} />
            <HistoryPlugin />
            {/* <AutoFocusPlugin /> */}
            <ListPlugin />
            <LinkPlugin />
            {/* <AutoLinkPlugin matchers={MATCHERS} /> */}
            <ListMaxIndentLevelPlugin maxDepth={7} />
            {/* <MarkdownShortcutPlugin transformers={TRANSFORMERS} /> */}
            <EditorInitializer />
          </div>
        </div>
      </div>
    </LexicalComposer>
  );
}
